import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject, PLATFORM_ID } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { LoggerService } from '@ppg/core/logger';

export const offlineModeInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const platformId = inject(PLATFORM_ID);
  const logger = inject(LoggerService);

  if (isPlatformBrowser(platformId) && !window.navigator.onLine) {
    logger.error('Application is offline');
    return throwError(() => new HttpErrorResponse({ status: 0, statusText: 'Offline' }));
  }

  return next(req);
};
