import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Mutation } from 'apollo-angular';

export interface UserResponse {
  statusCode: number;
}

export interface CreateUserVariables {
  userEmail: string;
}

@Injectable({
  providedIn: 'root',
})
export class CreateAppBannerDetailsMutation extends Mutation<UserResponse, CreateUserVariables> {
  override document = gql`
    mutation ($userEmail: EmailAddress!) {
      setUserBannerDismissedOn(userEmail: $userEmail) {
        statusCode
      }
    }
  `;
}
