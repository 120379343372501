import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { of, tap } from 'rxjs';

import { CurrentSiteService, CurrentSiteState, UrlService } from '@ppg/configuration';
import { ContentService } from '@ppg/core/content';
import { AppStaticRoutes } from '@ppg/core/enums';
import { LanguagesService } from '@ppg/core/language';

export const previewContentResolver = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const contentService = inject(ContentService);
  const currentSiteState = inject(CurrentSiteState);
  const languagesService = inject(LanguagesService);
  const currentSiteService = inject(CurrentSiteService);
  const location = inject(Location);
  const router = inject(Router);
  const urlService = inject(UrlService);

  const activatedUrl = route.url.map((segment) => segment.path).join('/');
  const queryStringAndHash = urlService.getQueryStringAndHash(state.url);
  const siteRootPath = currentSiteState.hubPagePath();
  const siteId = currentSiteState.siteId();
  const language = languagesService.language();

  if (!siteRootPath || !language || !siteId) {
    return of(null);
  }

  const codename = route.url[3]?.path;

  if (!codename) {
    return of(null);
  }

  return contentService.getPreviewContent(siteId, language, codename).pipe(
    tap((content) => {
      if (!content) {
        void router
          .navigateByUrl(currentSiteService.buildPagePath(language, AppStaticRoutes.NOT_FOUND), {
            skipLocationChange: true,
          })
          .then(() => location.replaceState(`${activatedUrl}${queryStringAndHash}`));
      }
    }),
  );
};
