import { inject, Injector, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { identity, map, Observable, of, take } from 'rxjs';

import { AppRouteItem } from '@ppg/core/content';
import { LanguageChangeParams, ChangeFlow } from '@ppg/core/language';
import { LoggerService } from '@ppg/core/logger';
import { AppRoutesService, getAppRouteItemFromRoute, getPathByAppRouteCodename } from '@ppg/core/navigation';

@Injectable({ providedIn: 'root' })
export class FeatureModuleChangeFlowService {
  readonly #router: Router = inject(Router);
  readonly #logger: LoggerService = inject(LoggerService);
  readonly #injector: Injector = inject(Injector);

  getChangeFlow(changeParams: LanguageChangeParams): Observable<ChangeFlow> {
    const appRouteItemFromRoute = this.#getAppRouteItemFromRoute();
    const appRoutesService = this.#injector.get(AppRoutesService);

    if (!appRouteItemFromRoute?.codename) {
      this.#logger.warn(
        '[FeatureModuleChangeFlowService][getChangeFlow] No codename found in app route item from route.',
      );

      return of({ urlUpdater: identity });
    }

    return appRoutesService.getAppRoutes(changeParams.newLanguage).pipe(
      take(1),
      map((appRoutes) => {
        const pathByAppRouteCodename = getPathByAppRouteCodename(appRoutes, appRouteItemFromRoute?.codename);

        if (pathByAppRouteCodename) {
          this.#logger.info(
            `[FeatureModuleChangeFlowService][getChangeFlow] Found matched route with the following path "${pathByAppRouteCodename}" for codename: ${appRouteItemFromRoute?.codename}`,
          );

          return {
            urlUpdater: ([url, extras]) => [url.replace(appRouteItemFromRoute.url, pathByAppRouteCodename), extras],
          };
        } else {
          this.#logger.warn(
            `[FeatureModuleChangeFlowService][getChangeFlow] No matched route found for codename: ${appRouteItemFromRoute?.codename}`,
          );
        }

        return { urlUpdater: identity };
      }),
    );
  }

  canApplyChangeFlow(): boolean {
    return Boolean(this.#getAppRouteItemFromRoute());
  }

  #getAppRouteItemFromRoute(): AppRouteItem | null {
    return getAppRouteItemFromRoute(this.#router.routerState.root.snapshot.firstChild?.data);
  }
}
