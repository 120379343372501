import { Route } from '@angular/router';

import { FeatureModule } from '@ppg/core/content';
import { ResourceItem } from '@ppg/core/enums';
import { globalSearchGuard } from '@ppg/core/guards';

export const CONTENT_BASE_ROUTES = {
  [FeatureModule.GlobalSearch]: {
    loadChildren: () => import('@ppg/features/global-search').then((component) => component.GlobalSearchRoutes),
    canMatch: [globalSearchGuard],
    title: ResourceItem.SEARCH_PAGE_TITLE,
  },
  [FeatureModule.ContactUsForm]: {
    loadChildren: () => import('@ppg/features/contact-us-form').then((component) => component.ContactUsFormRoutes),
  },
} satisfies Partial<Record<FeatureModule, Route | Route[]>>;
