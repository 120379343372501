import { UrlMatcher, UrlMatchResult, UrlSegment } from '@angular/router';

import { CONTENT_PREVIEW_MODE } from '@ppg/core/constants';

// Allow to match route with depth 3 {root_name}/{lang}/{route_condition}
const MAX_ROUTE_MATCH_DEPTH = 3;

export function matchByRoute(condition: string): UrlMatcher {
  return (segments: UrlSegment[]): UrlMatchResult | null => {
    const segmentMatchIndex = segments.findIndex(
      (segment) => segment.path.toLocaleLowerCase() == condition.toLocaleLowerCase(),
    );
    return !segments.length || segmentMatchIndex === -1 || segmentMatchIndex >= MAX_ROUTE_MATCH_DEPTH
      ? null
      : {
          consumed: segments.slice(0, segmentMatchIndex + 1),
        };
  };
}

export function previewMatcher(): UrlMatcher {
  return (segments: UrlSegment[]): UrlMatchResult | null => {
    const segmentMatchIndex = segments.findIndex(
      (segment) => segment.path.toLocaleLowerCase() === CONTENT_PREVIEW_MODE,
    );

    if (!segments.length || segmentMatchIndex === -1) {
      return null;
    }

    return {
      consumed: segments,
    };
  };
}

export function matchByCollectionAndLanguage(): UrlMatcher {
  return (segments: UrlSegment[]): UrlMatchResult | null => {
    const rootName = segments.map((segment) => segment.path).join('/');

    return {
      consumed: segments,
      posParams: {
        rootName: new UrlSegment(rootName, {}),
      },
    };
  };
}

export function matchForContactUs(): UrlMatcher {
  return (segments: UrlSegment[]): UrlMatchResult | null => {
    const segmentMatchIndex = segments.findIndex((segment) => segment.path.includes('contact-us'));

    if (!segments.length || segmentMatchIndex === -1 || segmentMatchIndex >= MAX_ROUTE_MATCH_DEPTH) return null;
    else if (segments.length === 3 && segmentMatchIndex === 1) {
      return {
        consumed: segments.slice(0, segmentMatchIndex + 1),
      };
    }

    return null;
  };
}

export function matchLanguageOnly(): UrlMatcher {
  return (segments: UrlSegment[]): UrlMatchResult | null => {
    const languageRexExp: RegExp = /[a-z]{2}-[A-Z]{2}$/i;

    if (segments.length === 1 && languageRexExp.test(segments[0].path)) {
      return {
        consumed: segments.slice(0, 1),
      };
    }

    return null;
  };
}

export function matchAnyExceptRoot(): UrlMatcher {
  return (segments: UrlSegment[]): UrlMatchResult | null => {
    return segments.length != 0
      ? {
          consumed: segments,
        }
      : null;
  };
}
