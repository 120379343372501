@if (isECommerceEnabled) {
  <app-smartbanner *ppgHideOnMobileAppView />
}
<ngx-loading-bar color="#0078a9" height="4px" />
<div class="viewport-container">
  <ppg-header
    *ppgHideOnMobileAppView
    class="ppg-header"
    data-test-id="app.ppgHeader"
    [cartItemsTotalQuantity]="cartItemsTotalQuantity()" />
  <main class="main-content">
    <router-outlet></router-outlet>
  </main>
</div>
<ppg-footer *ppgHideOnMobileAppView class="ppg-footer" data-test-id="app.ppgFooter" />

<p-toast [position]="isMobile() ? 'bottom-center' : 'top-right'" />

<ppg-header-sidebar />
