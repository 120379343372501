import { DOCUMENT } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptors, withXsrfConfiguration } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, inject, isDevMode } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TitleStrategy } from '@angular/router';
import { createMongoAbility, PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { provideTransloco, LangDefinition } from '@ngneat/transloco';
import { provideTranslocoLocale } from '@ngneat/transloco-locale';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { authInterceptor, provideDefaultAuth, refreshTokenInterceptor } from '@ppg/auth';
import { cacheMemoryInterceptor } from '@ppg/core/cache-memory';
import { API_BASE_URL, EXPERIENCE_API_REST_URL, WINDOW } from '@ppg/core/constants';
import { provideStaticContent } from '@ppg/core/content';
import { ssrCookieAttachingInterceptor } from '@ppg/core/cookies';
import { Locale } from '@ppg/core/enums';
import { EnvTransferService, provideEnvironmentConfig } from '@ppg/core/env-provider';
import { provideLanguageHydration, FeatureModuleChangeFlow } from '@ppg/core/language';
/**
 * TODO: Split order and product into smaller libs
 */
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ContactUsFormApiService, contactUsFormFactory, ContactUsFormUseCases } from '@ppg/features/contact-us-form';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ProductApiService, productFactory, ProductUseCases } from '@ppg/features/product';
import { provideAnalytics } from '@ppg/shared/analytics';
import { provideDidomi, provideGoogleTagManager } from '@ppg/shared/external-providers';
import { TranslocoHttpLoader } from '@ppg/shared/language';
import { PageTitleStrategy } from '@ppg/shared/routing';
import { provideTheming } from '@ppg/shared/themes';

import { provideLoadingBar, provideNgxMaskConfig } from './cdp/configs';
import { AuthGuardService } from './cdp/shared/services/auth-guard.service';
import { contextAttacherInterceptor } from './core/interceptors/context-attacher/context-attacher.interceptor';
import { errorHandlerInterceptor } from './core/interceptors/error-handler/error-handler.interceptor';
import { errorLoggerInterceptor } from './core/interceptors/error-logger/error-logger.interceptor';
import { mobileAppInterceptor } from './core/interceptors/mobile-app/mobile-app.interceptor';
import { offlineModeInterceptor } from './core/interceptors/offline-mode/offline-mode.interceptor';
import { requestLoggerInterceptor } from './core/interceptors/request-logger/request-logger.interceptor';
import { retryInterceptor } from './core/interceptors/retry/retry.interceptor';
import { timeoutInterceptor } from './core/interceptors/timeout/timeout.interceptor';
import { graphqlProvider } from './core/providers/graphql.config';
import { provideSessionConfiguration } from './core/providers/session-configuration-provider';
import { provideAppRouting } from './core/routing/provide-app-routing';
import { FeatureModuleChangeFlowService } from './core/routing/services/feature-module-change-flow.service';

const availableLangs: LangDefinition[] = Object.entries(Locale).map(
  ([label, id]: string[]): LangDefinition => ({ id, label }),
);

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: API_BASE_URL,
      useValue: '/',
    },
    // The role of Didomi is to share consent information with all the other scripts on the page. In order to do so, it MUST be placed before any other script.
    provideDidomi(),
    provideSessionConfiguration(),
    provideGoogleTagManager(),
    provideEnvironmentConfig(),
    provideLanguageHydration(),
    {
      provide: EXPERIENCE_API_REST_URL,
      useFactory: () => inject(EnvTransferService).rest(),
    },
    provideTheming(),
    provideStaticContent(),
    provideDefaultAuth(),
    { provide: createMongoAbility, useValue: createMongoAbility() },
    { provide: PureAbility, useExisting: createMongoAbility },
    importProvidersFrom(BrowserModule),
    importProvidersFrom(AbilityModule),
    importProvidersFrom(NgxPermissionsModule.forRoot()),
    provideAnimations(),
    provideHttpClient(
      withFetch(),
      withXsrfConfiguration({
        cookieName: 'XSRF-TOKEN',
        headerName: 'X-XSRF-TOKEN',
      }),
      withInterceptors([
        authInterceptor,
        refreshTokenInterceptor,
        mobileAppInterceptor,
        errorHandlerInterceptor,
        retryInterceptor,
        errorLoggerInterceptor,
        offlineModeInterceptor,
        timeoutInterceptor,
        ssrCookieAttachingInterceptor,
        requestLoggerInterceptor,
        contextAttacherInterceptor,
        cacheMemoryInterceptor,
      ]),
    ),
    graphqlProvider(),
    AuthGuardService,
    provideAppRouting(),
    provideClientHydration(),
    provideTransloco({
      config: {
        availableLangs,
        defaultLang: Locale.EN_US,
        fallbackLang: Locale.EN_US,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale(),
    DialogService,
    MessageService,
    provideLoadingBar,
    provideNgxMaskConfig,
    MessageService,
    {
      provide: ProductUseCases,
      useFactory: productFactory,
      deps: [ProductApiService],
    },
    {
      provide: ContactUsFormUseCases,
      useFactory: contactUsFormFactory,
      deps: [ContactUsFormApiService],
    },
    {
      provide: WINDOW,
      useFactory: (doc: Document) => doc.defaultView ?? window,
      deps: [DOCUMENT],
    },
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    {
      provide: FeatureModuleChangeFlow,
      useExisting: FeatureModuleChangeFlowService,
    },
    provideAnalytics(),
  ],
};
