import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { GoogleTagManagerConfiguration } from 'angular-google-tag-manager';

import { googleTagManagerConfigFactory } from './google-tag-manager-config.factory';
import { GoogleTagManagerProvider } from './google-tag-manager.provider';

export function provideGoogleTagManager(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: googleTagManagerConfigFactory,
      deps: [GoogleTagManagerProvider, GoogleTagManagerConfiguration],
    },
  ]);
}
