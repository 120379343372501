import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { of, tap } from 'rxjs';

import { CurrentSiteService, CurrentSiteState, PathService, UrlService } from '@ppg/configuration';
import { ContentService } from '@ppg/core/content';
import { AppStaticRoutes } from '@ppg/core/enums';
import { LanguagesService } from '@ppg/core/language';

export const contentResolver = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const contentService = inject(ContentService);
  const currentSiteState = inject(CurrentSiteState);
  const languagesService = inject(LanguagesService);
  const currentSiteService = inject(CurrentSiteService);
  const location = inject(Location);
  const router = inject(Router);
  const pathService = inject(PathService);
  const urlService = inject(UrlService);

  const activatedUrl = route.url.map((segment) => segment.path).join('/');
  const queryStringAndHash = urlService.getQueryStringAndHash(state.url);
  const siteRootPath = currentSiteState.hubPagePath();
  const siteId = currentSiteState.siteId();
  const isPreviewMode = currentSiteState.isPreviewMode();
  const language = languagesService.language();

  if (!siteRootPath || !language || !siteId) {
    return of(null);
  }

  if (isPreviewMode) {
    return of(null);
  }

  const urlSlug = urlService.getSlug(activatedUrl);

  return contentService.getContent(siteId, urlSlug, language).pipe(
    tap((content) => {
      if (!content) {
        void router
          .navigateByUrl(currentSiteService.buildPagePath(language, AppStaticRoutes.NOT_FOUND), {
            skipLocationChange: true,
          })
          .then(() => location.replaceState(`${activatedUrl}${queryStringAndHash}`));

        return;
      }

      const parentPagePath = content.breadcrumbs.filter((b) => b.url).at(-1)?.url;
      const requestedPageHierarchy = pathService.getPageHierarchy(activatedUrl);

      if (parentPagePath) {
        const parentPageUrlAndHierarchy = pathService.getPageUrlAndHierarchy(parentPagePath);

        if (requestedPageHierarchy === parentPageUrlAndHierarchy) {
          return;
        }

        const correctPagePath = currentSiteService.buildPagePath(
          language,
          urlSlug!,
          parentPageUrlAndHierarchy || undefined,
        );
        const correctPageUrl = `${correctPagePath}${queryStringAndHash}`;

        void router.navigateByUrl(correctPageUrl).then(() => location.replaceState(correctPageUrl));

        return;
      }

      if (requestedPageHierarchy) {
        const correctPagePath = currentSiteService.buildPagePath(language, urlSlug!);
        const correctPageUrl = `${correctPagePath}${queryStringAndHash}`;

        void router.navigateByUrl(correctPageUrl).then(() => location.replaceState(correctPageUrl));
      }
    }),
  );
};
