import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize, Observable, tap } from 'rxjs';

import { SERVER_REQUEST } from '@ppg/core/constants';
import { Dependencies } from '@ppg/core/enums';
import { LoggerService } from '@ppg/core/logger';

export const requestLoggerInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const loggerService = inject(LoggerService);
  const serverRequest = inject(SERVER_REQUEST, { optional: true });
  const startRequestTime = performance.now();

  let statusCode: number = 0;

  return next(req).pipe(
    tap({
      next: (event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          statusCode = event.status;
        }
      },
      error: (error: HttpErrorResponse) => {
        statusCode = error.status;
      },
    }),
    finalize(() => {
      const endTime = performance.now();
      const duration = Math.round(endTime - startRequestTime);
      const success = statusCode >= 200 && statusCode < 400;
      const sid = serverRequest?.sessionID ?? 'N/A';
      const name = `[HTTP] ${req.method} ${req.urlWithParams}`;

      loggerService.trackDependency({
        dependencyTypeName: Dependencies.REQUEST,
        target: 'Angular SSR',
        name,
        data: `SID: ${sid}`,
        duration,
        resultCode: statusCode,
        success,
      });
    }),
  );
};
