export const translationHashes = {
  "de-DE": "0f9c3790",
  "en-CA": "80d3bc84",
  "en-GB": "4b17307e",
  "en-US": "b50dd1c2",
  "es-ES": "2905fb9a",
  "es-US": "78c1e073",
  "formattings": "a7aab157",
  "fr-CA": "0ecf30e9",
  "nl-NL": "531d8e2c",
  "sv-SE": "4b0b844b"
} as {[lang: string]: string};