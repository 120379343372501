import { inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { combineLatest, map, Subscription, take } from 'rxjs';

import { ConfigManagerService } from '@ppg/configuration';
import { MAIN_CONTENT_ROUTE_DATA_KEY } from '@ppg/core/content';
import { ClientSiteSettingsKeys, ResourceItem } from '@ppg/core/enums';
import { Content, Site } from '@ppg/core/models';
import { ResourceService } from '@ppg/shared/resource';

@Injectable()
export class PageTitleStrategy extends TitleStrategy implements OnDestroy {
  readonly #title: Title = inject(Title);
  readonly #injector: Injector = inject(Injector);

  #subscription: Subscription | null = null;

  constructor() {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    this.updateContentRelatedTitle(routerState);
  }

  private updateContentRelatedTitle(routerState: RouterStateSnapshot): void {
    const resourceService = this.#injector.get(ResourceService);
    const configManagerService = this.#injector.get(ConfigManagerService);
    const mainContent: Content | null = routerState?.root?.children[0]?.data[MAIN_CONTENT_ROUTE_DATA_KEY] ?? null;
    const metaTitle: string | null = mainContent ? mainContent?.page?.meta?.title : null;
    const resourceKey: ResourceItem = <ResourceItem>this.buildTitle(routerState);
    const site: Site | null = configManagerService.getSiteByDomainName();
    const brandingAssetsTabText: string = configManagerService.getSiteSettingValueByKey(
      site,
      ClientSiteSettingsKeys.BrandingAssetsTabText,
    );

    if (metaTitle) {
      // If a meta title is available in the main content, set it as the page title
      this.#title.setTitle(metaTitle);
    } else if (brandingAssetsTabText) {
      // If branding assets tab text is available in Configuration Manager site settings, use it to set the page title
      const tabTextResourceKey = <ResourceItem>brandingAssetsTabText;
      const tabText = resourceService.getResourceByKey(tabTextResourceKey);

      if (tabText) {
        // If the tab text is found in the resources, set it as the page title
        this.#title.setTitle(tabText);
      }
    } else {
      // If neither meta title nor branding assets tab text is available
      if (this.#subscription) {
        this.#subscription.unsubscribe();
      }

      // Subscribe to global and site-specific resources
      this.#subscription = combineLatest([resourceService.globalResources$, resourceService.siteResources$])
        .pipe(
          take(1),
          map(() => resourceService.getResourceByKey(resourceKey || ResourceItem.PPG_BROWSER_TAB_TITLE)),
        )
        .subscribe((title: string) => {
          this.#title.setTitle(title);
        });
    }
  }

  ngOnDestroy(): void {
    if (this.#subscription) {
      this.#subscription.unsubscribe();
    }
  }
}
