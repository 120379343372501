import { Route } from '@angular/router';

import { CACHE_CONTROL_TOKEN, NOT_FOUND_PAGE_CACHE_CONTROL } from '@ppg/core/constants';
import { MAIN_CONTENT_ROUTE_DATA_KEY } from '@ppg/core/content';
import { AppStaticRoutes } from '@ppg/core/enums';
import { categoriesDummyResolver, contentResolver, previewContentResolver } from '@ppg/core/guards';
import { PRODUCT_LIST_CATEGORIES } from '@ppg/shared/product-api';

import {
  matchAnyExceptRoot,
  matchByCollectionAndLanguage,
  matchByRoute,
  previewMatcher,
} from '../../utils/route-matchers/route-matchers.utils';

export const STATIC_ROUTES: Route[] = [
  {
    matcher: matchByRoute(AppStaticRoutes.NOT_FOUND),
    loadComponent: () => import('@ppg/features/not-found').then((component) => component.NotfoundComponent),
    data: {
      [CACHE_CONTROL_TOKEN]: NOT_FOUND_PAGE_CACHE_CONTROL,
    },
  },
  {
    matcher: matchByRoute(AppStaticRoutes.SERVER_ERROR),
    loadComponent: () =>
      import('../../components/server-error/server-error.component').then(
        (component) => component.ServerErrorComponent,
      ),
  },
  {
    matcher: previewMatcher(),
    resolve: {
      [MAIN_CONTENT_ROUTE_DATA_KEY]: previewContentResolver,
      [PRODUCT_LIST_CATEGORIES]: categoriesDummyResolver,
    },
    loadComponent: () =>
      import('@ppg/core/page-resolver').then((component) => component.PreviewContentPageResolverComponent),
  },
  {
    matcher: matchByCollectionAndLanguage(),
    loadComponent: () => import('@ppg/core/page-resolver').then((component) => component.PageResolverComponent),
    resolve: { [MAIN_CONTENT_ROUTE_DATA_KEY]: contentResolver },
  },
  {
    matcher: matchAnyExceptRoot(),
    loadComponent: () => import('@ppg/features/not-found').then((component) => component.NotfoundComponent),
    data: {
      [CACHE_CONTROL_TOKEN]: NOT_FOUND_PAGE_CACHE_CONTROL,
    },
  },
];
