import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  afterNextRender,
  Component,
  computed,
  effect,
  HostListener,
  inject,
  Injector,
  NgZone,
  PLATFORM_ID,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ToastModule } from 'primeng/toast';
import { from, map, of, switchMap } from 'rxjs';

import { AuthService } from '@ppg/auth';
import { CurrentSiteState } from '@ppg/configuration';
import { Breakpoints } from '@ppg/core/enums';
import { LanguagesService } from '@ppg/core/language';
import { FooterComponent } from '@ppg/shared/footer';
import { HeaderComponent, HeaderSidebarComponent } from '@ppg/shared/header';
import { HideOnMobileAppViewDirective } from '@ppg/shared/mobile';

import { SmartbannerComponent } from './cdp/core/components/smartbanner/smartbanner.component';
import { PrimeNgBreakpoints } from './cdp/shared/enums/primeng-breakpoints.enum';

@Component({
  selector: 'ppg-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    HeaderSidebarComponent,
    FooterComponent,
    ToastModule,
    LoadingBarModule,
    HideOnMobileAppViewDirective,
    SmartbannerComponent,
    CommonModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  readonly MOBILE_NOTIFICATION = {
    [`${PrimeNgBreakpoints.Phone}px`]: { width: '90%', margin: 'auto', bottom: 0, top: 'unset' },
  };
  readonly #injector = inject(Injector);
  readonly #zone = inject(NgZone);
  readonly #document = inject(DOCUMENT);
  readonly #currentSiteState = inject(CurrentSiteState);
  readonly #authService = inject(AuthService);
  readonly #languagesService = inject(LanguagesService);
  readonly breakpointObserver = inject(BreakpointObserver);
  public readonly isECommerceEnabled = this.#currentSiteState.isECommerceEnabled();
  public readonly isMobile = toSignal(
    this.breakpointObserver.observe(Breakpoints.Mobile).pipe(map(({ matches }) => matches)),
  );
  readonly #shouldFetchCartQuantity = computed(
    () => this.#currentSiteState.isECommerceEnabled() && this.#authService.isAuthenticated(),
  );

  readonly cartItemsTotalQuantity = toSignal(
    toObservable(this.#shouldFetchCartQuantity).pipe(
      switchMap((shouldFetchCartQuantity) =>
        // Disable SSR for E-commerce using isPlatformBrowser
        shouldFetchCartQuantity && isPlatformBrowser(this.#injector.get(PLATFORM_ID))
          ? from(import('./cdp/modules/cart/services/cart.service')).pipe(
              switchMap((m) => this.#injector.get(m.CartService).cartItemsTotalQuantity$),
            )
          : of(0),
      ),
    ),
    {
      initialValue: 0,
    },
  );

  constructor() {
    afterNextRender(() => this.onResize());
    effect(() => {
      const lang = this.#languagesService.language();
      this.#document.documentElement.lang = lang;
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.#zone.runOutsideAngular(() => {
      const vh = (this.#document.defaultView?.innerHeight || 0) * 0.01;
      this.#document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }
}
