import { Platform } from '@angular/cdk/platform';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject, filter, Observable, switchMap } from 'rxjs';

import { PreferenceService } from '../../../modules/preference/services/preference.service';
import { DeviceService } from '../../../shared/services/device.service';
import { ConfigurationService } from '../../services/configuration.service';
import { UserInfoService } from '../../services/user-info.service';

@Component({
  selector: 'app-smartbanner',
  templateUrl: './smartbanner.component.html',
  styleUrls: ['./smartbanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, TranslocoModule],
  standalone: true,
})
export class SmartbannerComponent {
  url: string = null;
  smartbannerVisible$ = new BehaviorSubject<boolean>(false);
  smartBannerEnabled$: Observable<boolean>;

  constructor(
    private platformService: Platform,
    private preferenceService: PreferenceService,
    private userInfoService: UserInfoService,
    private deviceService: DeviceService,
    private configurationService: ConfigurationService,
  ) {
    this.smartBannerEnabled$ = this.configurationService.getSmartBannerEnabled();

    this.deviceService.isMobileView
      .pipe(
        filter((value) => value),
        switchMap(() => this.userInfoService.getUserInfo()),
        filter((userInfo) => !!userInfo),
        switchMap((userInfo) => this.preferenceService.getAppBanerDetails(userInfo.email)),
        takeUntilDestroyed(),
      )
      .subscribe((value) => {
        this.smartbannerVisible$.next(value);
      });

    if (this.platformService.ANDROID) {
      this.url = `https://onelink.to/cdp_footer_and?utm_source=cdp&utm_medium=footer`;
    } else if (this.platformService.IOS) {
      this.url = `https://onelink.to/cdp_footer_ios?utm_source=cdp&utm_medium=footer`;
    }
  }

  closeSmartBanner() {
    this.smartbannerVisible$.next(false);
    this.userInfoService.getUserInfo().subscribe((userInfo) => {
      this.preferenceService.createAppBannerDetails(userInfo.email).subscribe();
    });
  }
}
