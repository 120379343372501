import { ApplicationConfig, inject } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache, TypePolicy } from '@apollo/client/core';
import { APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { EnvTransferService } from '@ppg/core/env-provider';

export function apolloOptionsFactory(): ApolloClientOptions<unknown> {
  const httpLink = inject(HttpLink);
  const envTransferService = inject(EnvTransferService);
  const { graphql, graphqlAnonymous } = envTransferService.apim();

  return {
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === 'anonymous',
      httpLink.create({ uri: graphqlAnonymous }),
      httpLink.create({ uri: graphql }),
    ),
    cache: new InMemoryCache({
      typePolicies: {
        Configuration: { merge: true } as TypePolicy,
      },
    }),
  };
}

export function graphqlProvider(): ApplicationConfig['providers'] {
  return [
    Apollo,
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloOptionsFactory,
    },
  ];
}
