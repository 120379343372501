import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Query } from 'apollo-angular';

import { UserPreferences } from '../models/user-preferences.model';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesQuery extends Query<{ userPreferences: UserPreferences }, { userEmail: string }> {
  // eslint-disable-next-line
  override document = gql`
    query ($userEmail: EmailAddress!) {
      userPreferences(userEmail: $userEmail) {
        language
        isBannerVisible
      }
    }
  `;
}
