import { APP_INITIALIZER, inject, Provider } from '@angular/core';

import { SessionConfigurationService } from '@ppg/configuration';

export const provideSessionConfiguration = (): Provider => {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: () => {
      const sessionConfigurationService = inject(SessionConfigurationService);
      return () => sessionConfigurationService.getSessionConfiguration();
    },
  };
};
