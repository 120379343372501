import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { CreateAppBannerDetailsMutation } from '../queries/save-app-banner-details.mutation';
import { UserPreferencesQuery } from '../queries/user-preferences.query';

@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  constructor(
    private userPreferencesQuery: UserPreferencesQuery,
    private createAppBannerDetailsMutation: CreateAppBannerDetailsMutation,
  ) {}

  getAppBanerDetails(userEmail: string) {
    return this.userPreferencesQuery.fetch({ userEmail }).pipe(map((res) => res.data.userPreferences.isBannerVisible));
  }

  createAppBannerDetails(userEmail: string) {
    const newUserVariables = { userEmail: userEmail };
    return this.createAppBannerDetailsMutation.mutate(newUserVariables);
  }
}
