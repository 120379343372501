import { Route } from '@angular/router';

import { FeatureModule, MAIN_CONTENT_ROUTE_DATA_KEY } from '@ppg/core/content';
import { ClientSiteSettingsKeys } from '@ppg/core/enums';
import { featureFlagEnabledGuard, mixedContentResolver } from '@ppg/core/guards';
import { categoryResolver, PRODUCT_LIST_CATEGORIES } from '@ppg/shared/product-api';

export const PRODUCT_JOURNEY_ROUTES = {
  [FeatureModule.ProductJourneyList]: {
    loadChildren: () =>
      import('@ppg/core/page-resolver').then((component) =>
        component.mixedContentPageResolverRoutes([{ path: '', pathMatch: 'full' }]),
      ),
    resolve: {
      [MAIN_CONTENT_ROUTE_DATA_KEY]: mixedContentResolver,
      [PRODUCT_LIST_CATEGORIES]: categoryResolver,
    },
    canMatch: [featureFlagEnabledGuard(ClientSiteSettingsKeys.ProductJourneyProductListEnabled)],
  },
  [FeatureModule.ProductJourneyDetails]: {
    loadChildren: () =>
      import('@ppg/core/page-resolver').then((component) =>
        component.mixedContentPageResolverRoutes([{ path: ':productSlug/:productEMG' }]),
      ),
    resolve: {
      [MAIN_CONTENT_ROUTE_DATA_KEY]: mixedContentResolver,
      [PRODUCT_LIST_CATEGORIES]: categoryResolver,
    },
    canMatch: [featureFlagEnabledGuard(ClientSiteSettingsKeys.ProductJourneyProductDetailsIsEnabled)],
  },
} satisfies Partial<Record<FeatureModule, Route | Route[]>>;
