import { Route } from '@angular/router';

import { FeatureModule } from '@ppg/core/content';
import { BlankComponent } from '@ppg/core/navigation';

import { UserModuleUsage } from '../../../cdp/modules/user/user-module-usage.enum';
import { shouldApplyRedirectToProductsGuard, redirectToProductsGuard } from '../guards/redirect-to-products.guard';

export const ECOM_ROUTES = {
  [FeatureModule.CreateCashCustomer]: {
    loadChildren: () =>
      import('../../../cdp/modules/cash-customer/create-cash-customer.module').then((m) => m.CreateCashCustomerModule),
  },
  [FeatureModule.Registration]: {
    loadChildren: () =>
      import('../../../cdp/modules/registration/registration.module').then((m) => m.RegistrationModule),
  },
  [FeatureModule.CreateB2CCustomer]: {
    loadChildren: () =>
      import('../../../cdp/modules/b2c-customer-registration/create-b2c-customer.module').then(
        (m) => m.CreateB2CCustomerModule,
      ),
  },
  [FeatureModule.ProductsList]: {
    loadChildren: () =>
      import('../../../cdp/modules/products-list/products-list.module').then((m) => m.ProductsListModule),
  },
  [FeatureModule.Favorites]: {
    loadChildren: () => import('../../../cdp/modules/favorites/favorites.module').then((m) => m.FavoritesModule),
    data: { onPage: UserModuleUsage.UsersPage },
  },
  [FeatureModule.ImportAccounts]: {
    loadChildren: () =>
      import('../../../cdp/modules/import-accounts/import-accounts.module').then((m) => m.ImportAccountsModule),
  },
  [FeatureModule.Admin]: {
    loadChildren: () => import('../../../cdp/modules/admin/admin.module').then((m) => m.AdminModule),
  },
  [FeatureModule.Users]: {
    loadChildren: () => import('../../../cdp/modules/user/user.module').then((m) => m.UserModule),
    data: { onPage: UserModuleUsage.UsersPage },
  },
  [FeatureModule.PaymentMethods]: {
    loadChildren: () => import('../../../cdp/modules/payment/payment.module').then((m) => m.PaymentModule),
  },
  [FeatureModule.UserProfile]: {
    loadChildren: () => import('../../../cdp/modules/profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  [FeatureModule.Cart]: {
    loadChildren: () => import('../../../cdp/modules/cart/cart.module').then((m) => m.CartModule),
  },
  [FeatureModule.Customer]: {
    loadChildren: () => import('../../../cdp/modules/customer/customer.module').then((m) => m.CustomerModule),
  },
  [FeatureModule.Address]: {
    loadChildren: () => import('../../../cdp/modules/address/address.module').then((m) => m.AddressModule),
  },
  [FeatureModule.Job]: {
    loadChildren: () => import('../../../cdp/modules/job/job.module').then((m) => m.JobModule),
  },
  [FeatureModule.Order]: {
    loadChildren: () => import('../../../cdp/modules/order/order.module').then((m) => m.OrderModule),
  },
  [FeatureModule.InactiveUser]: {
    loadComponent: () => import('@ppg/features/inactive-user').then((component) => component.InactiveUserComponent),
  },
  [FeatureModule.B2bB2cCustomerRegistration]: {
    loadComponent: () =>
      import('@ppg/features/b2b-b2c-customer-registration').then((component) => component.AccountTypeComponent),
  },
} satisfies Partial<Record<FeatureModule, Route | Route[]>>;

export const ECOM_UTILITY_ROUTES = {
  [FeatureModule.RedirectToProducts]: {
    component: BlankComponent,
    pathMatch: 'full', // full url should match to avoid paths overlap
    canMatch: [shouldApplyRedirectToProductsGuard],
    canActivate: [redirectToProductsGuard],
  },
} satisfies Partial<Record<FeatureModule, Route | Route[]>>;
