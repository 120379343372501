import { Injectable, inject } from '@angular/core';

import { CurrentSiteState } from '@ppg/configuration';
import { ClientSiteSettingsKeys, EnvVarsNames } from '@ppg/core/enums';
import { EnvironmentService } from '@ppg/core/env-provider';

import { DidomiAdapter } from './didomi.adapter';
import { ExternalScriptProvider } from '../../abstraction/external-script-provider.model';

/**
 * Services used to initialize Didomi cookie consent notice.
 * On SSR phase, it inserts Didomi <script> tag into the <head> element as first child.
 */

@Injectable({
  providedIn: 'root',
})
export class DidomiProvider implements ExternalScriptProvider<void> {
  readonly currentSiteState: CurrentSiteState = inject(CurrentSiteState);
  readonly environmentService: EnvironmentService = inject(EnvironmentService);
  readonly didomiAdapter: DidomiAdapter = inject(DidomiAdapter);

  load(): void {
    const siteConsentNoticeId = this.currentSiteState.getSiteSettingValueByKey(
      ClientSiteSettingsKeys.DidomiConsentNoticeId,
    );

    const consentNoticeId = siteConsentNoticeId
      ? siteConsentNoticeId
      : this.environmentService.getEnvironmentVariable(EnvVarsNames.DIDOMI_DEFAULT_CONSENT_NOTICE_ID)!;

    return this.didomiAdapter.injectDidomiScript(consentNoticeId);
  }
}
