import { APP_INITIALIZER, EnvironmentProviders, inject, makeEnvironmentProviders } from '@angular/core';
import { provideRouter, Router, withInMemoryScrolling, withRouterConfig } from '@angular/router';

import { AppRoutesService, provideDynamicNavigation } from '@ppg/core/navigation';

import { RouterConfiguratorService } from './services/route-config-creator';

export function provideAppRouting(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideDynamicNavigation(() => inject(RouterConfiguratorService)),
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const router = inject(Router);
        const initialRouterConfig = inject(AppRoutesService).routerConfig();

        router.resetConfig(initialRouterConfig);

        return () => null;
      },
      multi: true,
    },
    provideRouter(
      [],
      withRouterConfig({ paramsInheritanceStrategy: 'always' }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
    ),
  ]);
}
