import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FooterComponent as PDSFooterComponent } from '@pds/custom-components/components/footer';
import { FooterBottomComponent } from '@pds/custom-components/components/footer-bottom';
import { FooterMenuComponent } from '@pds/custom-components/components/footer-menu';
import { FooterTopComponent } from '@pds/custom-components/components/footer-top';

import { FALLBACK_FOOTER_LOGO_SRC } from '@ppg/core/constants';
import { StaticContentService } from '@ppg/core/content';
import { ClientSiteSettingsKeys, ResourceItem, UrlTarget } from '@ppg/core/enums';

import { FooterService } from '../services/footer/footer.service';

@Component({
  selector: 'ppg-footer',
  standalone: true,
  imports: [PDSFooterComponent, FooterTopComponent, FooterMenuComponent, FooterBottomComponent],
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly footerService = inject(FooterService);
  private readonly router = inject(Router);
  private readonly domSanitizer = inject(DomSanitizer);
  private readonly staticContentService = inject(StaticContentService);
  private readonly isBrowser = isPlatformBrowser(this.platformId);

  protected readonly ResourceItem = ResourceItem;

  protected readonly mainItems = this.footerService.mainItems;
  protected readonly contactUsItem = this.footerService.contactUsItem;
  protected readonly socialLinks = this.footerService.socialLinks;
  protected readonly legalItem = computed(() =>
    this.domSanitizer.bypassSecurityTrustHtml(this.footerService.legalItem()),
  );
  protected readonly footerLogo = computed(
    () =>
      this.staticContentService.getStaticAsset(ClientSiteSettingsKeys.BrandingAssetsFooterLogo)?.url ??
      FALLBACK_FOOTER_LOGO_SRC ??
      '',
  );

  onContactUsClick() {
    if (!this.isBrowser) {
      return;
    }

    const contactUsItem = this.contactUsItem();

    switch (contactUsItem?.tag) {
      case 'absolute': {
        window.open(contactUsItem.url, contactUsItem.target);
        break;
      }
      case 'relative': {
        const url = this.router.serializeUrl(this.router.createUrlTree(contactUsItem.routerLink));

        if (contactUsItem.target === UrlTarget.BLANK) {
          window.open(url, contactUsItem.target);
        } else {
          void this.router.navigateByUrl(url);
        }

        break;
      }
    }
  }
}
