import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

import { CurrentSiteState } from '@ppg/configuration';
import { ClientSiteSettingsKeys } from '@ppg/core/enums';

export const featureFlagEnabledGuard = (flag: ClientSiteSettingsKeys) =>
  (() => {
    const currentSiteState = inject(CurrentSiteState);
    return currentSiteState.getSiteFlag(flag);
  }) satisfies CanMatchFn;
