import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, filter, take, tap } from 'rxjs';

import { LanguagesService } from '@ppg/core/language';

import { MarketLabelProviderService } from './market-label-provider.service';
import { UserInfoService } from '../../core/services/user-info.service';
import { CountryCode } from '../enums/country-code.enum';
import { UIPermissions } from '../enums/ui-permissions.enum';
import { openCookieConsentModal } from '../functions/open-cookie-consent-modal';

/**
 * @deprecated Too many roles and none of them is an AuthGuard
 */
@Injectable()
export class AuthGuardService {
  readonly #platformId = inject(PLATFORM_ID);
  readonly #userService = inject(UserInfoService);
  readonly #permissionsService = inject(NgxPermissionsService);
  readonly #marketLabelProvider = inject(MarketLabelProviderService);
  readonly #isAppInitialized = new BehaviorSubject<boolean>(null);
  readonly #languagesService = inject(LanguagesService);

  constructor() {
    this.#userService.user$
      .pipe(
        filter(Boolean),
        tap((userInfo) => {
          this.#permissionsService.loadPermissions(userInfo.permissions);

          // Admin page should be visible only when user have both permissions: ManageCustomers and ManageUsers
          this.#permissionsService.addPermission(UIPermissions.VIEW_ADMIN_PAGE, (_, permissionsStore) => {
            return (
              !!permissionsStore[UIPermissions.MANAGE_CUSTOMERS] && !!permissionsStore[UIPermissions.MANAGE_ALL_USERS]
            );
          });

          this.#marketLabelProvider.market = userInfo.countryCode as CountryCode;
        }),
        take(1),
      )
      .subscribe(() => this.#isAppInitialized.next(true));

    if (isPlatformBrowser(this.#platformId)) {
      openCookieConsentModal(this.#marketLabelProvider.labels.APP_NAME, this.#languagesService.language());
    }
  }

  canActivateChild() {
    return this.#isAppInitialized.pipe(take(1));
  }

  canActivate() {
    return this.#isAppInitialized.pipe(take(1));
  }
}
