import { Route } from '@angular/router';

import { FeatureModule, MAIN_CONTENT_ROUTE_DATA_KEY } from '@ppg/core/content';
import { ClientSiteSettingsKeys } from '@ppg/core/enums';
import { featureFlagEnabledGuard, mixedContentResolver } from '@ppg/core/guards';

export const COLOR_JOURNEY_ROUTES = {
  [FeatureModule.ColorFamilies]: {
    loadChildren: () =>
      import('@ppg/core/page-resolver').then((component) =>
        component.mixedContentPageResolverRoutes([
          { path: '', pathMatch: 'full' },
          { path: ':familyName' },
          { path: ':familyTypeName/:familyName' },
        ]),
      ),
    resolve: { [MAIN_CONTENT_ROUTE_DATA_KEY]: mixedContentResolver },
    canMatch: [featureFlagEnabledGuard(ClientSiteSettingsKeys.ColorJourneyIsEnabled)],
  },
  [FeatureModule.ColorCollection]: {
    loadChildren: () =>
      import('@ppg/core/page-resolver').then((component) =>
        component.mixedContentPageResolverRoutes([{ path: ':collectionName' }]),
      ),
    resolve: { [MAIN_CONTENT_ROUTE_DATA_KEY]: mixedContentResolver },
    canMatch: [featureFlagEnabledGuard(ClientSiteSettingsKeys.ColorJourneyIsEnabled)],
  },
  [FeatureModule.ColorDetails]: {
    loadChildren: () =>
      Promise.all([import('@ppg/core/page-resolver'), import('@ppg/features/color-details')]).then(
        ([component, colorDetailsModule]) =>
          component.mixedContentPageResolverRoutes([
            { path: `:${colorDetailsModule.COLOR_NAME_PARAM}/:${colorDetailsModule.COLOR_PRODUCT_NUMBER_PARAM}` },
          ]),
      ),
    resolve: { [MAIN_CONTENT_ROUTE_DATA_KEY]: mixedContentResolver },
    canMatch: [featureFlagEnabledGuard(ClientSiteSettingsKeys.ColorJourneyIsEnabled)],
  },
  [FeatureModule.FavoriteColors]: {
    loadChildren: () =>
      import('@ppg/core/page-resolver').then((component) =>
        component.mixedContentPageResolverRoutes([{ path: '', pathMatch: 'full' }]),
      ),
    resolve: { [MAIN_CONTENT_ROUTE_DATA_KEY]: mixedContentResolver },
    canMatch: [featureFlagEnabledGuard(ClientSiteSettingsKeys.ColorJourneyIsEnabled)],
  },
  [FeatureModule.Colors]: {
    loadChildren: () =>
      import('@ppg/core/page-resolver').then((component) =>
        component.mixedContentPageResolverRoutes([{ path: '', pathMatch: 'full' }]),
      ),
    resolve: { [MAIN_CONTENT_ROUTE_DATA_KEY]: mixedContentResolver },
  },
} satisfies Partial<Record<FeatureModule, Route | Route[]>>;
