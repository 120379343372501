<pds-footer>
  <pds-footer-top [logoSrc]="footerLogo()" logoAltText="PPG Logo"></pds-footer-top>

  <pds-footer-menu
    [menuItems]="mainItems()"
    [facebookUrl]="socialLinks()?.facebookLink || ''"
    [youtubeUrl]="socialLinks()?.youtubeLink || ''"
    [pinterestUrl]="socialLinks()?.pinterestLink || ''"
    [instagramUrl]="socialLinks()?.instagramLink || ''"
    [xUrl]="socialLinks()?.twitterLink || ''"
    [linkedInUrl]="socialLinks()?.linkedinLink || ''"
    [contactText]="contactUsItem()?.label!"
    (contactUsClick)="onContactUsClick()"></pds-footer-menu>

  <pds-footer-bottom>
    <div [innerHTML]="legalItem()"></div>
  </pds-footer-bottom>
</pds-footer>
