import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { themesFactory } from '../../factories/theme-factory.const';
import { ThemesService } from '../../services/themes.service';

export function provideTheming(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: themesFactory,
      deps: [ThemesService],
    },
  ]);
}
