import { HttpEventType, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { tap } from 'rxjs';

import { SsrCookieReconciliationService } from '../abstractions/ssr-cookie-reconciliation.service';

export const ssrCookieAttachingInterceptor: HttpInterceptorFn = (req, next) => {
  const cookieReconciliationService = inject(SsrCookieReconciliationService, { optional: true });

  if (!cookieReconciliationService) {
    return next(req);
  }

  return next(cookieReconciliationService.reconcileRequest(req)).pipe(
    tap((event) => {
      if (event.type === HttpEventType.Response) {
        cookieReconciliationService.reconcileResponse(event);
      }
    }),
  );
};
