import { Route } from '@angular/router';

import { FeatureModule } from '@ppg/core/content';

export const DISTRIBUTOR_LOCATOR_ROUTES = {
  [FeatureModule.DistributorLocator]: {
    loadChildren: () =>
      import('@ppg/features/distributor-locator').then((component) => component.DistributorLocatorRoutes),
  },
} satisfies Partial<Record<FeatureModule, Route | Route[]>>;
