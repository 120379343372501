import { Route } from '@angular/router';

import { FeatureModule } from '@ppg/core/content';

import { AUTH_ROUTES } from './auth-routes';
import { COLOR_JOURNEY_ROUTES } from './color-journey-routes';
import { CONTENT_BASE_ROUTES } from './content-routes';
import { DISTRIBUTOR_LOCATOR_ROUTES } from './distributor-locator-routes';
import { ECOM_ROUTES, ECOM_UTILITY_ROUTES } from './ecom-routes';
import { PRODUCT_JOURNEY_ROUTES } from './product-journey-routes';

export const FEATURE_MODULE_ROUTES = {
  ...AUTH_ROUTES,
  ...ECOM_ROUTES,
  ...ECOM_UTILITY_ROUTES,
  ...CONTENT_BASE_ROUTES,
  ...DISTRIBUTOR_LOCATOR_ROUTES,
  ...COLOR_JOURNEY_ROUTES,
  ...PRODUCT_JOURNEY_ROUTES,
} satisfies Record<FeatureModule, Route | Route[]>;
