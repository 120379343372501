import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, retry, throwError, timer } from 'rxjs';

import { DEFAULT_RETRY_COUNT, DEFAULT_TIME_BETWEEN_RETRIES } from '@ppg/core/constants';
import { LoggerService } from '@ppg/core/logger';

export const retryInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const logger = inject(LoggerService);

  return next(req).pipe(
    retry({
      count: DEFAULT_RETRY_COUNT,
      delay: (error) => shouldRetry(error, req, logger),
    }),
    catchError((error: HttpErrorResponse) => {
      return throwError(() => error);
    }),
  );
};

function shouldRetry(error: HttpErrorResponse, req: HttpRequest<unknown>, logger: LoggerService): Observable<0> {
  if (error.status === 0 || error.status >= HttpStatusCode.InternalServerError) {
    logger.warn(`Retrying request: ${req.urlWithParams}`);
    return timer(DEFAULT_TIME_BETWEEN_RETRIES);
  } else {
    throw error;
  }
}
