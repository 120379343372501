import { GoogleTagManagerConfiguration } from 'angular-google-tag-manager';

import { GoogleTagManagerProvider } from './google-tag-manager.provider';

export const googleTagManagerConfigFactory = (
  googleTagManagerProvider: GoogleTagManagerProvider,
  googleTagManagerConfiguration: GoogleTagManagerConfiguration,
) => {
  return () => {
    const config = googleTagManagerProvider.getConfig();
    googleTagManagerConfiguration.set(config);
  };
};
