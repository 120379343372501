import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, Observable, OperatorFunction, throwError, timeout, TimeoutError } from 'rxjs';

import { EnvVarsNames } from '@ppg/core/enums';
import { EnvironmentService } from '@ppg/core/env-provider';
import { LoggerService } from '@ppg/core/logger';

export const timeoutInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const logger = inject(LoggerService);
  const environmentService = inject(EnvironmentService);

  const defaultTimeout = Number(environmentService.getEnvironmentVariable(EnvVarsNames.DEFAULT_TIMEOUT));

  return next(req).pipe(
    // temporarily use timeout() instead of timeoutWhen() for performance investigation purposes
    timeout(defaultTimeout),
    // timeoutWhen(isTimeoutNeeded(req), Number(req.headers.get('timeout'))),
    catchError((error: HttpErrorResponse | TimeoutError) => {
      if (error instanceof TimeoutError) {
        logger.error(`TimeoutError: ${req.urlWithParams}`);
        return throwError(() => new HttpErrorResponse({ status: 0, statusText: error.message }));
      }
      return throwError(() => error);
    }),
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
function timeoutWhen<T>(condition: boolean, value: number): OperatorFunction<T, T> {
  return function (source$: Observable<T>): Observable<T> {
    return condition ? source$.pipe(timeout(value)) : source$;
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
function isTimeoutNeeded(req: HttpRequest<unknown>): boolean {
  return req.headers.has('timeout');
}
