import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { Observable } from 'rxjs';

import { translationHashes } from './translation-hashes';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http: HttpClient = inject(HttpClient);

  getTranslation(lang: string): Observable<Translation> {
    const translationPath = `/assets/i18n/${lang}.json`;
    const hash = translationHashes?.[lang] || '';

    return this.http.get<Translation>(`${translationPath}${hash ? '?hash=' + hash : ''}`);
  }
}
