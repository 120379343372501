import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router } from '@angular/router';

import { FeatureModule } from '@ppg/core/content';
import { LoggerService } from '@ppg/core/logger';
import { AppRoutesService, getAppRouteItemFromRoute, getPathByAppRouteFeatureModule } from '@ppg/core/navigation';

export const shouldApplyRedirectToProductsGuard = (() => {
  const logger = inject(LoggerService);
  const appRoutesService = inject(AppRoutesService);
  const path = getPathByAppRouteFeatureModule(appRoutesService.appRouteItems(), FeatureModule.ProductsList);

  if (!path) {
    logger.error(`[FeatureRedirectToProducts] redirect path cannot be calculated, skipping redirect`);
    return false;
  }

  return true;
}) satisfies CanMatchFn;

export const redirectToProductsGuard = ((route) => {
  const logger = inject(LoggerService);
  const router = inject(Router);
  const appRoutesService = inject(AppRoutesService);
  const path = getPathByAppRouteFeatureModule(appRoutesService.appRouteItems(), FeatureModule.ProductsList);

  logger.info(
    `[FeatureRedirectToProducts] redirecting to products feature with path: ${path} and queryPrams: ${JSON.stringify(route.queryParams)} from: ${getAppRouteItemFromRoute(route.data)?.url}`,
  );

  return router.createUrlTree([path], { queryParams: route.queryParams });
}) satisfies CanActivateFn;
