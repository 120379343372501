import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { of, tap } from 'rxjs';

import { CurrentSiteService, CurrentSiteState, UrlService } from '@ppg/configuration';
import { ContentService } from '@ppg/core/content';
import { AppStaticRoutes } from '@ppg/core/enums';
import { LanguagesService } from '@ppg/core/language';
import { LoggerService } from '@ppg/core/logger';
import { getAppRouteItemFromRoute } from '@ppg/core/navigation';

export const mixedContentResolver = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const logger = inject(LoggerService);
  const contentService = inject(ContentService);
  const currentSiteState = inject(CurrentSiteState);
  const currentSiteService = inject(CurrentSiteService);
  const languagesService = inject(LanguagesService);
  const urlService = inject(UrlService);
  const router = inject(Router);
  const location = inject(Location);

  const queryStringAndHash = urlService.getQueryStringAndHash(state.url);

  const activatedUrl = route.url.map((segment) => segment.path).join('/');
  const siteId = currentSiteState.siteId();
  const isPreviewMode = currentSiteState.isPreviewMode();
  const language = languagesService.language();
  const appRouteItem = getAppRouteItemFromRoute(route.data);

  if (!appRouteItem) {
    logger.error('[mixedContentResolver] cannot find appRouteItem data in route config');
    return of(null);
  }

  if (!language) {
    logger.error('[mixedContentResolver] missing language, skipping content retrieval');
    return of(null);
  }

  if (!siteId) {
    logger.error('[mixedContentResolver] site cannot be identified, skipping content retrieval');
    return of(null);
  }

  if (isPreviewMode) {
    logger.info('[mixedContentResolver] preview mode not supported, skipping content retrieval');
    return of(null);
  }

  const urlSlug = urlService.getSlug(appRouteItem.url);

  return contentService.getContent(siteId, urlSlug, language).pipe(
    tap((content) => {
      if (!content) {
        logger.info('[mixedContentResolver] content not found, redirecting to 404 page');
        void router
          .navigateByUrl(currentSiteService.buildPagePath(language, AppStaticRoutes.NOT_FOUND), {
            skipLocationChange: true,
          })
          .then(() => location.replaceState(`${activatedUrl}${queryStringAndHash}`));

        return;
      }
    }),
  );
};
