import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

import { CurrentSiteState } from '@ppg/configuration';
import { ClientSiteSettingsKeys } from '@ppg/core/enums';

export const globalSearchGuard = (() => {
  const currentSiteState: CurrentSiteState = inject(CurrentSiteState);

  return currentSiteState.getSiteFlag(ClientSiteSettingsKeys.AlgoliaIsActive);
}) satisfies CanMatchFn;
