import { HttpInterceptorFn } from '@angular/common/http';
import { inject, untracked } from '@angular/core';

import { CurrentSiteState } from '@ppg/configuration';
import { LanguagesService, LanguageResolutionService } from '@ppg/core/language';

export const contextAttacherInterceptor: HttpInterceptorFn = (req, next) => {
  const languageService = inject(LanguagesService);
  const currentSiteState = inject(CurrentSiteState);

  let activeCountryCode = untracked(() => languageService.countryCode());
  const currentSiteId = untracked(() => currentSiteState.currentSite()?.id);

  if (!activeCountryCode) {
    // Get the language from the first valid source if it isn't fully initialized
    activeCountryCode = inject(LanguageResolutionService).resolveLanguage(req.url).language.split('-').pop();
  }
  if (activeCountryCode) {
    const headers = req.headers.set('CountryCodeOfRequest', activeCountryCode);
    req = req.clone({ headers });
  }

  if (currentSiteId) {
    const headers = req.headers.set('site-id', currentSiteId.toString());
    req = req.clone({ headers });
  }

  return next(req);
};
