import { isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID, Signal } from '@angular/core';
import { Route, Router, UrlTree } from '@angular/router';

import { isAuthenticatedGuard, isUserActiveGuard } from '@ppg/auth';
import { FeatureModule } from '@ppg/core/content';
import { LanguagesService } from '@ppg/core/language';
import { LoggerService } from '@ppg/core/logger';
import { BlankComponent, GuardFn, RouterConfigurator } from '@ppg/core/navigation';

import { AuthGuardService } from '../../../cdp/shared/services/auth-guard.service';
import { ECOM_ROUTES } from '../routes/ecom-routes';
import { FEATURE_MODULE_ROUTES } from '../routes/feature-module-routes';
import { STATIC_ROUTES } from '../routes/static-routes';

const EMPTY_PAGE_DISABLE_ECOM_SSR_WORKAROUND_URL = 'cde-frontend-empty-page-disable-ecom-ssr-workaround';

@Injectable({ providedIn: 'root' })
export class RouterConfiguratorService implements RouterConfigurator {
  readonly #logger = inject(LoggerService);
  readonly #languagesService = inject(LanguagesService);
  readonly #isServer = isPlatformServer(inject(PLATFORM_ID));

  readonly language: Signal<string> = this.#languagesService.language;

  getStaticRoutes(): Route[] {
    const staticRoutes = [...STATIC_ROUTES];

    if (this.#isServer) {
      // Disable SSR for E-commerce pages
      staticRoutes.unshift(this.#createEcomEmptyPageRoute());
    }

    return staticRoutes;
  }

  getFeatureModuleRoute(featureModule: FeatureModule): Route[] {
    const featureModuleRouteConfig: Route[] | Route | undefined = FEATURE_MODULE_ROUTES[featureModule];

    if (!featureModuleRouteConfig) {
      this.#logger.error(`[RouterConfiguratorService] cannot find route config for ${featureModule} feature module`);
      return [];
    }

    const routeConfigs: Route[] = Array.isArray(featureModuleRouteConfig)
      ? [...featureModuleRouteConfig]
      : [featureModuleRouteConfig];

    if (this.#isServer) {
      // Disable SSR for E-commerce pages
      return this.#wrapEcomModulesWithDisabledSSRFeature(routeConfigs);
    }

    return routeConfigs;
  }

  getAuthGuards(featureModule: FeatureModule): GuardFn[] {
    const authGuards: GuardFn[] = [isAuthenticatedGuard];

    // Disable SSR for the pages that have auth guards (E-commerce pages SSR workaround)
    if (this.#isServer) {
      return authGuards;
    }

    authGuards.push(() => inject(AuthGuardService).canActivate());

    if (featureModule !== FeatureModule.InactiveUser) {
      authGuards.push(isUserActiveGuard);
    }

    return authGuards;
  }

  #createEcomEmptyPageRoute(): Route {
    return {
      path: this.#getEcomEmptyPageRoutePath(),
      component: BlankComponent,
    };
  }

  #getEcomEmptyPageRoutePath(): string {
    return [this.language(), EMPTY_PAGE_DISABLE_ECOM_SSR_WORKAROUND_URL].filter(Boolean).join('/');
  }

  #wrapEcomModulesWithDisabledSSRFeature(routes: Route[]): Route[] {
    const ecomModules = Object.values(ECOM_ROUTES).flat();
    return routes.map((route) => {
      if (ecomModules.find((cfg) => cfg === route)) {
        const updatedRoute = { ...route };
        delete updatedRoute['loadChildren'];
        delete updatedRoute['loadComponent'];
        updatedRoute.component = BlankComponent;
        updatedRoute.canMatch = [...(updatedRoute.canMatch ?? [])];
        updatedRoute.canMatch.push(this.#redirectToDisabledSSRPageGuard.bind(this));
        return updatedRoute;
      }
      return route;
    });
  }

  /**
   * Check whether route contains auth guard, if auth required, first try to check auth result, after that apply SSR workaround
   */
  #redirectToDisabledSSRPageGuard(route: Route): boolean | UrlTree {
    const authGuard: typeof isAuthenticatedGuard | null = route.canActivate?.find(
      (guard) => guard === isAuthenticatedGuard,
    );

    const authResult = authGuard ? authGuard() : true;

    if (!authGuard) {
      this.#logger.info(
        '[RouterConfiguratorService][redirectToDisabledSSRPageGuard] No auth guard found, skipping auth check',
      );
    }

    if (authResult === true) {
      this.#logger.info(
        '[RouterConfiguratorService][redirectToDisabledSSRPageGuard] Redirect to empty page to disable SSR for E-commerce pages',
      );
      return inject(Router).parseUrl(`/${this.#getEcomEmptyPageRoutePath()}`);
    } else {
      this.#logger.info(
        '[RouterConfiguratorService][redirectToDisabledSSRPageGuard] Auth check failed, skipping SSR workaround',
      );
    }

    return authResult;
  }
}
