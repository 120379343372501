import { Route } from '@angular/router';
import { provideTranslocoScope } from '@ngneat/transloco';

import { FeatureModule } from '@ppg/core/content';

export const AUTH_ROUTES = {
  [FeatureModule.Authentication]: {
    loadChildren: () => import('@ppg/features/auth').then((m) => m.AuthRoutes),
    providers: [provideTranslocoScope({ scope: 'auth', alias: 'AUTH' })],
  },
} satisfies Partial<Record<FeatureModule, Route | Route[]>>;
