import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject, PLATFORM_ID } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { SERVER_REQUEST } from '@ppg/core/constants';
import { LoggerService } from '@ppg/core/logger';

export const errorLoggerInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const loggerService = inject(LoggerService);
  const platformId = inject(PLATFORM_ID);
  const serverRequest = inject(SERVER_REQUEST, { optional: true });

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (isPlatformServer(platformId)) {
        const sid = serverRequest?.sessionID ?? 'N/A';
        loggerService.error(`[HTTP Error] [SID: ${sid}] ${error.status}: ${error.statusText} ${req.urlWithParams}`);
      } else if (error.error instanceof ErrorEvent) {
        loggerService.error(`Client-Side Error: ${error.error?.message}`);
      }

      return throwError(() => error);
    }),
  );
};
