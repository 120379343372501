import { COOKIE_CSS_CLASS } from '../consts/market-labels';

export function openCookieConsentModal(appName: string, language: string) {
  const mutationObserver = new MutationObserver((mutationList, observer) => {
    const cookieSettingsNode = mutationList.find((c) => (c.target as any).classList.contains(COOKIE_CSS_CLASS));
    if (cookieSettingsNode) {
      (window as any).cookieConsent({
        brandName: appName,
        language: new Intl.Locale(language).language,
      });
      observer.disconnect();
    }
  });
  mutationObserver.observe(document, { subtree: true, childList: true, attributes: true, characterData: false });
}
