import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';

import { DISABLE_TOKEN_REFRESH } from '@ppg/core/constants';
import { MobileAppDetectorService } from '@ppg/core/services';

export const mobileAppInterceptor: HttpInterceptorFn = (req, next) => {
  const mobileAppDetectorService = inject(MobileAppDetectorService);

  return next(req).pipe(
    catchError((error) => {
      if (mobileAppDetectorService.isMobileApp()) {
        req.context.set(DISABLE_TOKEN_REFRESH, true);
      }

      return throwError(() => error);
    }),
  );
};
