@if ((smartbannerVisible$ | async) && (smartBannerEnabled$ | async)) {
  <span class="smartbanner-close" (click)="closeSmartBanner()">&times;</span>
  <div class="smartbanner" id="smartabanner">
    <div class="smartbanner-container">
      <span class="smartbanner-icon"></span>
      <div class="smartbanner-info">
        <div class="smartbanner-title">{{ 'SMART_BANNER.TITLE' | transloco }}</div>
        <div class="smartbanner-subtitle">{{ 'SMART_BANNER.SUB_TITLE' | transloco }}</div>
      </div>
      <a (click)="closeSmartBanner()" [href]="url" target="_blank" rel="noopener" class="smartbanner-button">
        <span class="smartbanner-button-text">{{ 'SMART_BANNER.VIEW' | transloco }}</span>
      </a>
    </div>
  </div>
}
