import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';

import { urlContainsSegment } from '@ppg/auth';
import { AppStaticRoutes, EnvVarsNames } from '@ppg/core/enums';
import { EnvironmentService } from '@ppg/core/env-provider';
import { LanguagesService } from '@ppg/core/language';
import { LoggerService } from '@ppg/core/logger';

export const errorHandlerInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const contentApiUrl = inject(EnvironmentService).getEnvironmentVariable(EnvVarsNames.APIM_CONTENT_API_BASE_URL);
  const language = inject(LanguagesService).language;
  const logger = inject(LoggerService);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (urlContainsSegment(req.url, `${contentApiUrl}`) && error.status === HttpStatusCode.InternalServerError) {
        const route = `/${language()}/${AppStaticRoutes.SERVER_ERROR}`;
        logger.error(`Navigating to ${route} due to ${error.message}`);
        router.navigate([route]);
        return throwError(() => error);
      } else {
        return throwError(() => error);
      }
    }),
  );
};
