import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { didomiFactory } from './didomi-factory.const';
import { DidomiProvider } from './didomi.provider';

export function provideDidomi(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: didomiFactory,
      deps: [DidomiProvider],
    },
  ]);
}
