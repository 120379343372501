import { inject, Injectable } from '@angular/core';
import { GoogleTagManagerConfig } from 'angular-google-tag-manager';

import { CurrentSiteState } from '@ppg/configuration';
import { ClientSiteSettingsKeys, EnvVarsNames } from '@ppg/core/enums';
import { EnvironmentService } from '@ppg/core/env-provider';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerProvider {
  readonly #currentSiteState: CurrentSiteState = inject(CurrentSiteState);
  readonly environmentService: EnvironmentService = inject(EnvironmentService);

  getConfig(): GoogleTagManagerConfig {
    const siteGTMId = this.#currentSiteState.getSiteSettingValueByKey(ClientSiteSettingsKeys.GTMId);

    const GTMId = siteGTMId ? siteGTMId : this.environmentService.getEnvironmentVariable(EnvVarsNames.GTM_DEFAULT_ID)!;

    return { id: GTMId };
  }
}
