import { computed, inject, Injectable } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { of, switchMap } from 'rxjs';

import { ConfigManagerService, CurrentSiteService, CurrentSiteState, UrlService } from '@ppg/configuration';
import { ContentService } from '@ppg/core/content';
import { ClientSiteSettingsKeys, ResourceItem } from '@ppg/core/enums';
import { LanguagesService } from '@ppg/core/language';
import {
  FooterChildNavigationItem,
  FooterLinks,
  FooterMainItem,
  FooterMainType,
  Link,
  SocialSharingContent,
} from '@ppg/core/models';
import { ResourceService } from '@ppg/shared/resource';

@Injectable({ providedIn: 'root' })
export class FooterService {
  private readonly contentService = inject(ContentService);
  private readonly languagesService = inject(LanguagesService);
  private readonly currentSiteState = inject(CurrentSiteState);
  private readonly currentSiteService = inject(CurrentSiteService);
  private readonly urlService = inject(UrlService);
  private readonly configManagerService = inject(ConfigManagerService);
  private readonly resourceService = inject(ResourceService);

  private readonly currentSiteId = computed(() => this.currentSiteState.currentSite()?.id);

  private readonly footerParams = computed(() => ({
    siteId: this.currentSiteId(),
    language: this.languagesService.language(),
  }));

  private readonly footerInfo$ = toObservable(this.footerParams).pipe(
    switchMap((params) => {
      if (!params.siteId || !params.language) {
        return of(null);
      }

      return this.contentService.getFooter(params.siteId, params.language);
    }),
  );

  private readonly footerInfo = toSignal(this.footerInfo$, { initialValue: null });

  readonly mainItems = computed(() => {
    const mainItems = this.footerInfo()
      ?.items.filter((i) => i.type == FooterMainType.navigation)
      .slice(0, -1) as FooterMainItem[];

    return mainItems?.map(this.mapFooterMainItemToFooterLinks.bind(this)) ?? [];
  });

  readonly contactUsItem = computed(() => {
    const contactUsItem = this.footerInfo()
      ?.items.filter((i) => i.type == FooterMainType.navigation)
      .at(-1) as FooterMainItem;

    if (!contactUsItem) {
      return null;
    }

    return this.mapFooterMainItemToFooterLinks(contactUsItem).items[0];
  });

  readonly legalItem = computed(() => {
    const siteLegalFooterSetting = this.currentSiteState.getSiteSettingValueByKey(
      ClientSiteSettingsKeys.KenticoCodeNamesFooterLegalMenu,
    );

    if (siteLegalFooterSetting) {
      return this.resourceService.getResourceByKey(siteLegalFooterSetting as ResourceItem);
    }

    const rootSite = this.configManagerService.rootSite();

    const rootSiteFooterSettings = this.configManagerService.getSiteSettingValueByKey(
      rootSite,
      ClientSiteSettingsKeys.KenticoCodeNamesFooterLegalMenu,
    );

    if (rootSiteFooterSettings) {
      return this.resourceService.getResourceByKey(rootSiteFooterSettings as ResourceItem);
    }

    return this.resourceService.getResourceByKey(ResourceItem.LEGAL_FOOTER);
  });

  readonly socialLinks = computed<SocialSharingContent | null>(() => this.footerInfo()?.social?.content ?? null);

  private mapFooterMainItemToFooterLinks(item: FooterMainItem): FooterLinks {
    return {
      label: item.name,
      items: item.items.map(this.mapChildFooterItemToLink.bind(this)),
    };
  }

  private mapChildFooterItemToLink(item: FooterChildNavigationItem): Link {
    if (!this.urlService.isAbsoluteUrl(item.url)) {
      return {
        tag: 'relative',

        label: item.name,
        routerLink: [item.url],
        target: item.target,
      };
    }

    if (this.currentSiteService.isCurrentSiteDomainUrl(item.url)) {
      const itemUrl = new URL(item.url);

      return {
        tag: 'relative',

        label: item.name,
        routerLink: [itemUrl.pathname + itemUrl.search],
        target: item.target,
      };
    }

    return {
      tag: 'absolute',

      label: item.name,
      url: item.url,
      target: item.target,
    };
  }
}
